import React, {Fragment} from "react";
import {ComponentTitle, ErrorErrorComponent, InfoText} from "../util/helper";
import oneIT from "../../images/OneIT.pdf"

const ExpiryPage = () => {
    return (
        <Fragment>
            <ComponentTitle text={"Oops! This link seems to be expired"}/>
            <br/>
            <InfoText as="p">Apologies, but the link sent to you has expired
            <br/>Please contact the<a href={oneIT} target="_blank" rel="noopener noreferrer"> OneIT ServiceDesk </a>to receive a new link
            </InfoText>
        </Fragment>
    )
};

export default ExpiryPage;