import styled from 'styled-components';
import {Flex, Text} from "@qc-modules/components";
import {themeDarken} from "@qc-modules/styles";
import {device} from "../../../lib/breakpoints";

export const FooterLinkContainer = styled(Flex)`
    justify-content: flex-start;
    margin-bottom: 10px;

    @media ${device.laptop} {
        grid-column: 2/8;
        grid-row: 2/3;
        color: #FFF;
    }
`;

export const StyledFooterCopyrightContainer = styled.div`
    color: #B2B2B2;
    opacity:1;
    font-size: 12px;
    margin-bottom: 5px;
       
    @media ${device.tablet} {
        grid-column: 2/8;
        grid-row: 3/4;
        margin-bottom: 10px;
    }
       
    @media ${device.laptop} {
        color: #FFF;
        opacity:.6;
        margin-bottom: 0;
     }
`;

export const FooterLink = styled(Text)`
        text-decoration: none;
        opacity: ${props => props.opacity}
        color: #B2B2B2;
        
        &:visited {
            color:  #B2B2B2;
            text-decoration: none;
        };
        
        &:hover {
            color:${() => themeDarken(0.15,  '#B2B2B2')};
        };
        
        &:not(:last-child) {
            margin-right: 10px;
        }
        
        font-size: 12px;
        
        @media ${device.mobile} {
            &:not(:last-child) {
                margin-right: 24px;
            }
        }
        
       @media ${device.laptop} {
            margin-right: 16px;
            color: #FFF;
            
            &:visited {
                color:  #FFF;
                text-decoration: none;
            };
        
            &:hover {
                color:${() => themeDarken(0.15,  '#B2B2B2')};
            };
       }
`;