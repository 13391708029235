import React, {useEffect, useState} from 'react';
import axios from 'axios'
import {requestWrapper} from '../components/util/helper'
import {qDomainRegex} from "../components/util/validations";
import {getUrlParams, getUrlParameter} from "../utils/Utils";
import DuoModalContainer from '../components/common/DuoModalContainer';
import ReactDOM from 'react-dom';
//import {getCookie, getUrlParams, hexEncode, setCookie, setHashCookie, triggerTealiumTracking, getUrlParameter} from "../utils/Utils";

const useLogin = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [empno, setEmpno] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [emailDomainError, setEmailDomainError] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [validityFailed, setValidityFailed] = useState(false);

    const [usernameError, setUsernameError] = useState(false);
    const [empnoError, setEmpnoError] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(true);

    const validateFields = {email, username, empno};
    const loginMethods = {setEmail, setUsername, setEmpno};
    const loginErrorFields = {emailError, emailDomainError, usernameError, empnoError};
    const loginErrorMethods = {setEmailError, setUsernameError, setEmpnoError};



    useEffect(() => {
        const validateForm = () => {

            // setLoginFailed(false);
            setEmailDomainError(false);
            //explicitly handling , filed validation should trigger after its dirty only
            const isFormDirtyAndInvalid = [email, username, empno].some(obj => {
                return obj === '';
            });

            if (isFormDirtyAndInvalid) {
                setIsFormInvalid(isFormDirtyAndInvalid)
            } else {
                //check for validation rules of each field
                setIsFormInvalid(Object.values(loginErrorFields).some(obj => {
                    return obj
                }))
            }
        };

        //validate the form each time the state values changes for form fields
        validateForm()
    }, [email, username, empno, loginErrorFields]);


//?TARGET=$SM$HTTPS%3a%2f%2fmyaccount-tst%2equalcomm%2ecom%2fstatic%2fregistration%2fwelcome%2ehtml&SMAGENTNAME=$SM$U2HE%2fLuhsdXVq5U0BI1JpFjrRbnEGRSrMxu8pKL%2bk%2bVvJy4uyNdOYVrVx9iVswy3

    let backendUrl = process.env.REACT_APP_APEX_API_ENDPOINT
    const rootElement = document.getElementById('modal');
    let login = requestWrapper(async () => {
        const params = getUrlParams();
        getFormVariables(params);
        setLoginFailed(false);
        setValidityFailed(false);
        try{
            axios.post(backendUrl+'/api/process', {...params}, {
                headers: {
                  // 'application/json' is the modern content-type for JSON, but some
                  // older servers may use 'text/json'.
                  // See: http://bit.ly/text-json
                  'content-type': 'text/json'
                } } )
            .then(function (response) {
                console.log(response);                
                if(response != null && response.status == 200 && response.data.status == 200)
                {
                    setLoginFailed(false);
                    setValidityFailed(false);
                    const username = params['USERNAME']
                    sessionStorage.setItem('username',username);
                    ReactDOM.render(
                        <DuoModalContainer params={params} show={true} modalType={2}/>,rootElement);

                        // axios.post(backendUrl+'/api/duo', {...params}, {
                        //     headers: {
                        //       // 'application/json' is the modern content-type for JSON, but some
                        //       // older servers may use 'text/json'.
                        //       // See: http://bit.ly/text-json
                        //       'content-type': 'text/json'
                        //     } } ).then(function (response) {
                        //     console.log(response);
                        //     if(response != null && response.status == 200 && response.data.status == 200)
                        //     {
                                
                        //         ReactDOM.unmountComponentAtNode(rootElement)
                        //         sessionStorage.setItem('myMail', response.data.mail);
                        //         sessionStorage.setItem('myPass', response.data.text);
                        //         if(window.location.hostname == 'localhost')
                        //         {
                        //             window.location.href = window.location.protocol+"//"+window.location.hostname+":3000/nextsteps"
                        //             return true;
                        //         }
                        //         window.location.href = window.location.protocol+"//"+window.location.hostname+"/nextsteps"
                                
                        //     }
                        //     else{
                        //         ReactDOM.unmountComponentAtNode(rootElement)
                        //         ReactDOM.render(
                        //             <DuoModalContainer params={params} show={true} modalType={2}/>,rootElement);
                        //             return false
                        //     }
                            
                        //  })
                        //  .catch(error => {
                        //     console.log(error.message);
                        // });

                        return true;
                }
                else
                { 
                    if(response != null && response.status == 200 && response.data.status == 401) 
                    {
                        setValidityFailed(true);
                        return false
                    }
                    else{
                        setLoginFailed(true);
                        return false
                    }
                }
            })
            .catch(error => {
                console.log(error.message);
            });
        }
        catch(error)
        {
            console.log(error.message);
        }
        // let data = await document.getElementsByName("validate")[0].submit()
    });

    const setHiddenVariables = (params) => {
        document.getElementsByName('id')[0].value = (params.id);
        document.getElementsByName('code')[0].value = (params.code);
    };

    const getFormVariables = (params) => {
        params['PERSONAL_EMAIL_ADDRESS']= document.getElementById('personalEmailAdress').value.toLowerCase();
        params['USERNAME'] =  document.getElementById('user').value.toLowerCase();
        params['EMP_NO'] = document.getElementById('empNum').value.toLowerCase();
    };

    const verifyDomain = () => {
        const domainError = email && email.indexOf("@") > -1 ? qDomainRegex.test(email) : false;
        setEmailDomainError(domainError);
    };

    const updateStateAndValidateField = (value, fieldProps) => {

        const {updateState} = fieldProps;
        updateState(value);
        // const errors = validateField(value, fieldProps);
    };

    const getTargetUrl = () => {
        let target = ""
        return target
    }

    const replaceAnyOpenIdJunk = (targeturl) => {
        let result = targeturl
        const openIdIndex = targeturl.indexOf("openid")
        if (openIdIndex > -1) {
            result = targeturl.substring(0, openIdIndex)
        }
        return result
    }
    
    return {
        ...validateFields, ...loginMethods, ...loginErrorFields, ...loginErrorMethods,
        isFormInvalid, verifyDomain, emailDomainError, loginFailed, validityFailed,
        actionCallBack: updateStateAndValidateField,
        login,
        targetUrl: getTargetUrl()
    }

};

export default useLogin;
