import React, {useState} from 'react'
import {Box} from "@qc-modules/components";
import {ValidateView} from './ValidateView'
import {ValidateFields} from './ValidateFields'
import WebUiForm from '../util/WebUiForm'
import axios from 'axios'
import LoadingPage from "../common/LoadingPage";
import ExpiryPage from "../common/ExpiryPage";

const Validate = () => {
  const blockData = ValidateFields().loginFields;

  const [urlState, setUrlState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSent, setIsSent] = useState(false);
  
  let url = window.location.href
  let backendUrl = process.env.REACT_APP_APEX_API_ENDPOINT
  const session_url = sessionStorage.getItem('url');

  if(!isSent)
  {
    axios.post(backendUrl+'/api/validate', {url:url}, {
      headers: {
        // 'application/json' is the modern content-type for JSON, but some
        // older servers may use 'text/json'.
        // See: http://bit.ly/text-json
        'content-type': 'text/json'
      } }).then(function (response) {
        console.log(response);
        setIsLoading(false)
        if(response != null && response.status == 200 && response.data.status == 200) {
            setUrlState(true)
            sessionStorage.setItem('url', url);
        }
        else {
            setUrlState(false)
        }
    })
    .catch(error => {
    console.log(error.message);
    });

    setIsSent(true)
}

  return ( <>
            { !isLoading && urlState && 
              <Box>
                <WebUiForm component={ValidateView} blockData={blockData}/>
              </Box>
            }
            {
                !isLoading && !urlState &&
                <ExpiryPage/>
            }
            {
                isLoading && 
                <LoadingPage/>
            }
            </>

  )
};

export default Validate

