import React from 'react';
import {isNotEmpty} from "../util/validations";
import useLogin from '../../hooks/useValidate'
import {Box, Cta} from '@qc-modules/components';
import {HtmlField} from '../util/helper';

export const ValidateFields = () => {
    const loginState = useLogin();

    const loginFields = {
        name: "validate",
        loginFailed: loginState.loginFailed,
        validityFailed: loginState.validityFailed,
        targetUrl:loginState.targetUrl,
        formControl: {
            buttonLabel: 'Submit',
            handleSubmit: (e) => {
                e.preventDefault();
                if(!loginState.isFormInvalid){
                    let res = loginState.login()
                    if(!res)
                    {
                        loginState.setLoginFailed(true);
                    }
                }

            },
            component: LoginFormControl,
            isFormInValid: loginState.isFormInvalid,
            visible: true
        },

        fields: [
            {
                name: "personalEmailAdress",
                htmlTag: "input",
                label: 'Your Email Address*',
                type: "text",
                placeholder: "Your Email Address",
                width: 1,
                bottomLabel: "The non Qualcomm email address used for past communications with Qualcomm",
                isError: loginState.emailError,
                errorCallBack: loginState.setEmailError,
                value: loginState.email,
                actionCallBack: loginState.actionCallBack,
                updateState: loginState.setEmail,
                component: LoginDataRow, order: 1,
                isFieldEditable: true,
                visible: true,
                onBlur: loginState.verifyDomain,
                validationRules: [
                    {method: isNotEmpty, message: ""},
                ]

            },
            {
                name: "user",
                htmlTag: "input",
                label: 'Qualcomm Username*',
                type: "text",
                placeholder: "Qualcomm Username",
                width: 1,
                bottomLabel: "This information has been previously communicated to your non Qualcomm email address",
                isError: loginState.usernameError,
                errorCallBack: loginState.setUsernameError,
                value: loginState.username,
                actionCallBack: loginState.actionCallBack,
                updateState: loginState.setUsername,
                component: LoginDataRow, order: 2,
                isFieldEditable: true,
                visible: true,
                validationRules: [
                    {method: isNotEmpty, message: ""}
                ]

            },
            {
                name: "empNum",
                htmlTag: "input",
                label: 'Employee ID*',
                type: "text",
                placeholder: "Employee ID",
                width: 1,
                bottomLabel: "This information has been previously communicated to your non Qualcomm email address",
                isError: loginState.empnoError,
                errorCallBack: loginState.setEmpnoError,
                value: loginState.empno,
                actionCallBack: loginState.actionCallBack,
                updateState: loginState.setEmpno,
                component: LoginDataRow, order: 3,
                isFieldEditable: true,
                visible: true,
                validationRules: [
                    {method: isNotEmpty, message: ""}
                ]

            }

        ]
    };
    return {
        loginFields
    }
};

const LoginDataRow = (props) => {
    return (
        <Box mt={4}>
            <HtmlField {...props}/>
        </Box>
    )
};

const LoginFormControl = (props) => {
    return (
        <Cta
             type={'submit'}
             onClick={(event) => props.handleSubmit(event)}
             version="secondary"
             disabled={props.isFormInValid}
             my={4}
        >
            Submit
        </Cta>
    )
};