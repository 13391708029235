import {DuoPassModal} from "./DuoPassModal";
import React, {useState, useEffect} from 'react'

const DuoModalContainer = ({params, show, modalType, error = false}) => {

    const [showModal, setShowModal] = useState(show);
    const [showModalType, setShowModalType] = useState(modalType);

    useEffect(() => {
        setShowModal(show);
    }, []);


    return (
        <>
            {/* { showModal && showModalType === 1 &&
            <DuoModal params={params} showModal={showModal} setShowModal={setShowModal} setShowModalType={setShowModalType} /> } */}

            { showModal && showModalType === 2 &&
            <DuoPassModal params={params} showModal={showModal} setShowModal={setShowModal} setShowModalType={setShowModalType} error={error}/> }
        </>
    )
};

export default DuoModalContainer;