import React, {Fragment} from "react";
import {ComponentTitle, InfoText} from "../util/helper";

const ErrorPage = () => {
    return (
        <Fragment>
            <ComponentTitle text={"Oops! This link seems to be broken"}/>
            <br/>
            <InfoText as="p">
            Please use the link provided to you by Qualcomm OneIT to proceed
            </InfoText>
        </Fragment>
    )
};

export default ErrorPage;