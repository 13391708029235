import React from 'react';
import {FooterCopyrightText, FooterLinkConfig} from "../common";
import {FooterLink, FooterLinkContainer, StyledFooterCopyrightContainer} from "./FooterStyle";

export const FooterLinks = () => {
    const opacity = 1;
    const weight = 400;

    const linkOptions = {
        opacity,
        weight,
        tag: 'a',
        target: '_blank',
        rel: 'noopener noreferrer'
    };

    return (
        <FooterLinkContainer>
            {FooterLinkConfig.linkOptions.map((linkOption) => (
                <FooterLink key={linkOption.id}
                            href={linkOption.href} tag={linkOptions.tag} target={linkOptions.target} rel={linkOptions.rel}>{linkOption.displayText}</FooterLink>
            ))}
        </FooterLinkContainer>
    )
};


export const FooterCopyright = () => {
    return (
        <StyledFooterCopyrightContainer>
            <FooterCopyrightText/>
        </StyledFooterCopyrightContainer>
    )
};
