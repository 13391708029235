import React, {Fragment}  from 'react'
import {ComponentTitle, InfoText, InfoTextBold, InfoTextBoldBlue, InfoTextBoldRed, InfoTextBoldBig, InfoTextBoldBigger} from "../util/helper";
import styled from 'styled-components';
import { useParams } from "react-router";
import {Redirect} from "react-router-dom";
import axios from 'axios'

const NextSteps = () => {
  // let { mail } = useParams();
  const mail = sessionStorage.getItem('myMail');
  const pass = sessionStorage.getItem('myPass');
  const isAquisitionUserText = sessionStorage.getItem('isAquisitionUser');
  let isAquisitionUser = null;
  let isNotAquisitionUser = null;
  const url = sessionStorage.getItem('url');
  const username = sessionStorage.getItem('username');
  
  if('true' === isAquisitionUserText.toLowerCase())
  {
    isAquisitionUser = true;
  }
  else 
  {
    isAquisitionUser = false;
  }

  isNotAquisitionUser = !isAquisitionUser;

  if(!mail || !pass)
  {
    return(<Redirect to="/"/>)
  }

  
  if(url)
  {
    let backendUrl = process.env.REACT_APP_APEX_API_ENDPOINT
    axios.post(backendUrl+'/api/next', {url:url, username:username}, {
      headers: {
        // 'application/json' is the modern content-type for JSON, but some
        // older servers may use 'text/json'.
        // See: http://bit.ly/text-json
        'content-type': 'text/json'
      } } ).then(function (response) {
      console.log(response);
      if(response != null && response.status == 200 && response.data.status == 200) {
          sessionStorage.removeItem('url');
      }
    })
    .catch(error => {
    console.log(error.message);
    });
    sessionStorage.removeItem('url');
  }

  
  return (
      <Fragment>
          <InfoTextBoldBigger>Use your Qualcomm assigned email address and temporary password to log in</InfoTextBoldBigger><br/><br/>

          <InfoTextBoldBig>• Qualcomm Assigned Email Address: </InfoTextBoldBig><InfoTextBoldBlue>{mail}</InfoTextBoldBlue><br/>
          <InfoTextBoldBig>• Qualcomm Temporary Password: </InfoTextBoldBig><InfoTextBoldBlue>{pass}</InfoTextBoldBlue><br/>
          <br/>
          <InfoTextBoldRed>This password will expire in 7 days</InfoTextBoldRed><br/><br/>
          <InfoText>Follow </InfoText>
          { 
            isNotAquisitionUser && <InfoTextBold><u><a href='https://qualcomm.service-now.com/sp?id=kb_article_view&sysparm_article=KB0053987' target="_blank" rel="noopener noreferrer">these instructions</a></u></InfoTextBold>
          }
          {
            isAquisitionUser && <InfoTextBold><u><a href='https://qualcomm.service-now.com/sp?id=kb_article_view&sysparm_article=KB0056805' target="_blank" rel="noopener noreferrer">these instructions</a></u></InfoTextBold>
          }
          <InfoText> to change your password.<br/>You will be prompted to log in with the above credentials</InfoText>
      </Fragment>
  )
};

export default NextSteps

