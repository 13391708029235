import React, {Fragment} from "react";
import {ComponentTitle, InfoText} from "../util/helper";

const LoadingPage = () => {
    return (
        <Fragment>
            <ComponentTitle text={"Loading..."}/>
        </Fragment>
    )
};

export default LoadingPage;