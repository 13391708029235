import {Text} from "@qc-modules/components";
import styled from 'styled-components';
import {device} from "../../../lib/breakpoints";

export const StyledFooterLegalTextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: column;
    background-color: white;
    font-size: 12px;
    
    @media ${device.laptop} {
        grid-column: 9/14;
        grid-row: 1/4;
        justify-content: end;
        margin-bottom: 0;
        max-width: 718px;
    }
`;


export const StyledFooterLegalText = styled(Text)`
    color:  #BBC0C8;
    opacity: ${props => props.opacity};
    margin-bottom: 10px;

    @media ${device.laptop} {
        margin-bottom: 10px;
    }
`;
