import React from 'react';
import {Box} from '@qc-modules/components'
const WebUiForm = (props) => {
    const {blockData} = props;
    const Component = props.component;
    const OutSideFormComps = props.outSideFormComps;

    return (
        <Box>
            <form onSubmit={blockData.formControl.handleSubmit} name={blockData.name} method={'POST'}>
                <Component isEdit={true} blockData={blockData}/>
            </form>
            { OutSideFormComps &&   <OutSideFormComps isEdit={true} blockData={blockData}></OutSideFormComps>}

        </Box>
    )
}

export default WebUiForm