import React from 'react';
import {ComponentTitle, ComponentDescriptionNew, ErrorInfoComponent, ErrorErrorComponent, PrepareFormElement, PrepareFormElements} from "../util/helper";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import {Box} from '@qc-modules/components';

export const Space = styled(Box)`
   height:2px;
`;
export const ValidateView = (props) => {

    const {blockData} = props;

    return (
        <>
            {
                blockData &&
                <>
                    <ComponentTitle text={"Welcome to Qualcomm!"}/>
                    <ComponentDescriptionNew text={"Please provide the following information to view your account details"}/>
                    {blockData.loginFailed && <ErrorInfoComponent text={"Entered data does not authorize you to proceed"}/>}
                    {blockData.validityFailed && <ErrorErrorComponent text0={"Apologies, but the link sent to you has expired."} text1={"Please contact the "} text2={"OneIT ServiceDesk"} text3={" to receive a new link."}/>}
                    <PrepareFormElements fields={blockData.fields} isEditPage={true}/>

                    <PrepareFormElement field={blockData.formControl}
                                        isEditPage={true}
                                        index={blockData.fields.length}/>

                    <input type="hidden" name="PERSONAL_EMAIL_ADDRESS" value=""/>
                    <input type="hidden" name="USERNAME" value=""/>
                    <input type="hidden" name="EMP_NO" value=""/>
                    <input type="hidden" name="id" value="$$id$$"/>
                    <input type="hidden" name="code" value="$$code$$"/>
                </>
            }
        </>
    )
};

