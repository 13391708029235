import React from 'react';
import {Flex, Text, Box, Cta} from "@qc-modules/components";
import {LoadingIcon} from "../util/Loading";
import styled from "styled-components"
import duopass from "../../images/DuoPass.png";
import {device} from '../../lib/breakpoints'
import shield from "../../images/icon-shield-person.svg";
import axios from 'axios'
import ReactDOM from 'react-dom';
import DuoModalContainer from './DuoModalContainer';
import helpFileEN from "../../images/help_en.pdf"
import helpFileCN from "../../images/help_cn.pdf"
import helpFileGR from "../../images/help_gr.pdf"

export const ModalContainer = styled(Flex)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    align-items: center;
    justify-content: center;
    z-index: 200;
    min-width: 375px;
`;

export const Modal = styled(Flex)`
    background-color: #FFF;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 690px;
    height: 430px;
    border: 1px solid #677283;
    border-radius: 12px;
    padding: 0 50px;
    min-width: 450px;
    z-index: 250;
    opacity: 1;
    display:grid;
`;

export const StyledLoadingIcon = styled(LoadingIcon)`
margin: 35px auto;
`;


export const StyledLoadingContainer = styled.div`
width: 100px;
height: 100px;
`;

export const Inputtt = styled.input`
width: 230px;
height: 50px;
font-size: 28px;
padding: 10px;
padding-left: 16px;
background: white;
border: 1px solid #677283;
border-radius: 8px;
letter-spacing: 16px;
`;

export const StyledXContainer = styled.div`
grid-row: 1;
grid-column:4;
float: right;
cursor: pointer;
`;

export const StyledTextContainer = styled.div`
width: 450px;
height: 400px;
grid-row: 4/20;
grid-column: 1/3;
`;


export const StyledImageContainer = styled.div`
width: 200px;
height: 400px;
grid-row: 2/20;
grid-column: 3/4;
background-image: url(${duopass});
background-size: contain;
background-repeat: no-repeat;
`;

export const StyledShieldContainer = styled.div`
width: 100px;
height: 100px;
background-image: url(${shield});
background-size: contain;
background-repeat: no-repeat;
margin-left: 30px;
margin-top: 10px;
margin-bottom: 10px;

`;

const TextContainer1 = styled(Text)`
    font-weight: 400;
    color: #3253DC;
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 0px;
    width: 450px;
    word-wrap: break-word;
    display:block;
    line-height: 25px;
    
    @media ${device.mobile} {
    align-self: center;
    font-size: 30px;
    }

    
     @media ${device.tablet} {
     font-size: 36px;
    }
    
    @media ${device.laptop} {
      font-size: 23px;
     }
`;

const TextContainer2 = styled(Text)`
    font-weight: 400;
    color: #3253DC;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 45px;
    width: 450px;
    word-wrap: break-word;
    display:block;
    line-height: 25px;
    
    @media ${device.mobile} {
    align-self: center;
    font-size: 30px;
    }

    
     @media ${device.tablet} {
     font-size: 36px;
    }
    
    @media ${device.laptop} {
      font-size: 23px;
     }
`;

const FooterTextContainer = styled(Text)`
    font-weight: 400;
    color: #3253DC;
    padding: 20px;
    width: 450px;
    word-wrap: break-word;
    display:block;
    line-height: 10px;
    text-decoration: underline;
    cursor: pointer;
    
    @media ${device.mobile} {
    align-self: center;
    font-size: 30px;
    }

    
     @media ${device.tablet} {
     font-size: 36px;
    }
    
    @media ${device.laptop} {
      font-size: 14px;
     }
`;

const FooterTextContainer2 = styled(Text)`
    font-weight: 400;
    color: #3253DC;
    padding: 20px;
    width: 450px;
    word-wrap: break-word;
    display:block;
    line-height: 10px;
    cursor: pointer;
    
    @media ${device.mobile} {
    align-self: center;
    font-size: 30px;
    }

    
     @media ${device.tablet} {
     font-size: 36px;
    }
    
    @media ${device.laptop} {
      font-size: 14px;
     }
`;

const U = styled(Text)`
text-decoration: underline;
padding-right: 15px;
`;

const PasscodeContainer = styled(Text)`
    font-weight: 400;
    font-size: 10px;
    color: #67728e;
    padding: 20px;
    width: 420px;
    height: 120px;
    word-wrap: break-word;
    display:grid;
    line-height: 10px;
    
    @media ${device.mobile} {
    align-self: center;
    font-size: 30px;
    }

    
     @media ${device.tablet} {
     font-size: 36px;
    }
    
    @media ${device.laptop} {
      font-size: 14px;
     }
`;

export const PI =styled.div`
width: 235px;
grid-row: 2;
background-color: white;
font-size: 10px;
`;
export const PS =styled.button`
width: 80px;
height: 50px;
grid-row: 2;
border-radius: 25px;
background-color: #1c37a5;
color: white;
font-family:"QualcommNext", Arial, sans-serif;
font-size:16px;
margin-left: 5px;
cursor: pointer;
`;

export const ER = styled(Text)`
grid-row: 1;
line-height: 15px;
grid-column: 1/3;
color: red;
font-family:"QualcommNext", Arial, sans-serif;
font-size:14px;
margin-left: 0px;
margin-bottom: 10px;
`;

export const DuoPassModal = ({params, showModal, setShowModal, setShowModalType, error}) => {
    // const buttonClickHandler = () => {
    //     window.location = 'https://browser-update.org/update-browser.html#3.2.14:www.qualcomm.com';
    // };
    let otp = null

    const closeModal = () => {
        setShowModal(!showModal);
        window.location.href = window.location.href + ''
    };

    const changeModal = () => {
        setShowModalType(1)
    };

    const changeOtp = (e) => {
        otp = e;
    }

    const PasscodeInput = () => {
         return (
             <Box>
                 <Inputtt name={'otp'} onChange={(event)=>{changeOtp(event.target.value)}}/>
             </Box>
         )
     };

    const duoPassSubmit = () => {
        const rootElement = document.getElementById('modal');
        params['otp'] = otp
        let backendUrl = process.env.REACT_APP_APEX_API_ENDPOINT
        axios.post(backendUrl+'/api/duopass', {...params}, {
            headers: {
              // 'application/json' is the modern content-type for JSON, but some
              // older servers may use 'text/json'.
              // See: http://bit.ly/text-json
              'content-type': 'text/json'
            } } ).then(function (response) {
            console.log(response);
            if(response != null && response.status == 200 && response.data.status == 200)
            {
                ReactDOM.unmountComponentAtNode(rootElement)
                sessionStorage.setItem('myMail', response.data.mail);
                sessionStorage.setItem('myPass', response.data.text);
                sessionStorage.setItem('isAquisitionUser', response.data.isaquisitionuser);
                if(window.location.hostname == 'localhost')
                {
                    window.location.href = window.location.protocol+"//"+window.location.hostname+":3000/nextsteps"
                    return true;
                }
                window.location.href = window.location.protocol+"//"+window.location.hostname+"/nextsteps"
            }
            else{
                ReactDOM.unmountComponentAtNode(rootElement)
                ReactDOM.render(
                    <DuoModalContainer params={params} show={true} modalType={2} error={true}/>,rootElement);
            }
            
         })
         .catch(error => {
            console.log(error.message);
        });
    };

    return (
    <ModalContainer>
        <Modal data-testid='response-modal'>
            <StyledXContainer onClick={closeModal}>x</StyledXContainer>
            <StyledTextContainer>
            <TextContainer1>Please enter the passcode as displayed on your registered Duo device</TextContainer1>
            <PasscodeContainer><PI><PasscodeInput/></PI><PS onClick={duoPassSubmit}>GO</PS>{ error && <ER>Please refresh the passcode on your Duo device and re-enter</ER>}</PasscodeContainer>
            <TextContainer2>If you opted out of Duo, please enter the one time passcode provided to you by OneIT ServiceDesk</TextContainer2>
            {/* <FooterTextContainer onClick={changeModal}>Go back to Duo Push</FooterTextContainer> */}
            <FooterTextContainer2>
                <U><a href={helpFileEN} download="help" target="_blank" rel="noopener noreferrer">Need help?</a></U>
                <U><a href={helpFileCN} download="help" target="_blank" rel="noopener noreferrer">需要協助?</a></U>
                <U><a href={helpFileGR} download="help" target="_blank" rel="noopener noreferrer">Brauchst du Hilfe?</a></U>
            </FooterTextContainer2>
            </StyledTextContainer>
            <StyledImageContainer/>
        </Modal>
    </ModalContainer>
    )
};
