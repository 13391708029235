import React, {useState} from 'react';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import history from "../context/History";
// import {getLoginUrl, getTargetUrl, logout} from '../utils/Utils';
// import ForgotPassword from "../components/forgotPassword/ForgotPassword";
// import ResetPassword from "../components/resetPassword/ResetPassword";
import Validate from "../components/validate/Validate";
import NextSteps from "../components/validate/NextSteps";
// import Welcome from "../components/welcome/Welcome";
// import ProtectedRoute from '../routers/ProtectedRoute';
// import Loading from '../components/util/Loading';
import ErrorPage from "../components/common/ErrorPage";
// import SignUp from "../components/register2/signup/SignUp";
// import Registration from "../components/register2/registration/Registration";
// import ResponsePage from "../components/common/ResponsePage";
// import RegistrationSuccess from "../components/common/RegistrationSuccess";
// import {SignUpSuccessPage} from "../components/register2/signup/SignUpResponse";
// import TokenRequest from "../components/register2/token/TokenRequest";
// import {
//     AvailableRegistrationResponse,
//     errorRegistrationResponse,
//     HasRoleResponse,
//     inProgressRegistrationResponse
// } from "../components/register2/registration/RegistrationResponse";
// import {default as UserDetails2} from "../components/profile2/UserDetails";
// import SalesAccessRequestLanding from "../components/register2/response/SalesAccessRequestLanding";
// import {profileConstants} from "../components/util/appConstants2";
// import {getUserTargetUrl} from "../api/profile-api";

// const targetUrl = (currentRoute) => {
//     const url = `${getLoginUrl()}?state=${currentRoute}`;
//     return (url);
// };

// const redirectToOauth = (returnUrl) => {
//     window.location = targetUrl(returnUrl);
// };

// const redirectToTarget = async () => {
//     const userTargetUrl = await getUserTargetUrl()
//     if(userTargetUrl && userTargetUrl !== "") {
//         window.location.href = userTargetUrl
//     } else {
//         window.location.pathname = "/"
//     }

// };

// const redirectToLogout = () => {
//     logout(getTargetUrl())

// };
const Routes = () => {
    const [returnUrl, setReturnUrl] = useState('');

    return (
        <Router history={history}>
            <Switch>
                <Route path="/" exact={true}>
                    <Route component={ErrorPage}/>
                </Route>
                <Route path="/validate" component={Validate} exact={true}/>
                <Route path="/nextsteps" component={NextSteps} exact={true}/>
                <Route component={ErrorPage}/>
            </Switch>
        </Router>
    )
};

export default Routes;