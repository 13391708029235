// import moment from 'moment';
// import {trackEvent} from "../tealium/eventTrack";
// import {getSessionToken} from "../services/authService";

// const jwt = require('jsonwebtoken');

// export const currentYear = () => {
//     return (
//         moment().year()
//     );
// };

// export const cookieExpirationTime = (seconds) => {
//     const expirationSeconds = seconds ? seconds : 86400;
//     return  moment().add(expirationSeconds, 'seconds').valueOf();
// };

// export const hexEncode = (s) => {
//     var chrsz = 8;
//     var hexcase = 0;

//     const str2binb = (str) => {
//         var bin = [];
//         var mask = (1 << chrsz) - 1;
//         for (var i = 0; i < str.length * chrsz; i += chrsz) {
//             bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32);
//         }
//         return bin;
//     };

//     const Utf8Encode = (string) => {
//         string = string.replace(/\r\n/g, "\n");
//         var utftext = "";

//         for (var n = 0; n < string.length; n++) {

//             var c = string.charCodeAt(n);

//             if (c < 128) {
//                 utftext += String.fromCharCode(c);
//             } else if ((c > 127) && (c < 2048)) {
//                 utftext += String.fromCharCode((c >> 6) | 192);
//                 utftext += String.fromCharCode((c & 63) | 128);
//             } else {
//                 utftext += String.fromCharCode((c >> 12) | 224);
//                 utftext += String.fromCharCode(((c >> 6) & 63) | 128);
//                 utftext += String.fromCharCode((c & 63) | 128);
//             }

//         }

//         return utftext;
//     };

//     const binb2hex = (binarray) => {
//         var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
//         var str = "";
//         for (var i = 0; i < binarray.length * 4; i++) {
//             str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
//                 hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
//         }
//         return str;
//     };

//     s = Utf8Encode(s);
//     return binb2hex(str2binb(s));
// };

// export const setCookie = (name, value, secondsToExpiration) => {
//     const expiresAt = cookieExpirationTime(secondsToExpiration);
//     const expireString = moment(expiresAt).utc();
//     const cookieValue = escape(value) || "";

//     document.cookie = `${name}=${cookieValue}; expires=${expireString}`;
// };

// //migrated from cp-reg
// //original: https://stackoverflow.com/questions/4825683/how-do-i-create-and-read-a-value-from-cookie
// export const getCookie = (cookieName) => {
//     let i, x, y, cookieArray = document.cookie.split(";");
//     for (i = 0; i < cookieArray.length; i++) {
//         x = cookieArray[i].substr(0, cookieArray[i].indexOf("="));
//         y = cookieArray[i].substr(cookieArray[i].indexOf("=") + 1);
//         x = x.replace(/^\s+|\s+$/g, "");
//         if (x === cookieName) {
//             return unescape(y);
//         }
//     }
//     return null;
// };

// //TODO: leaving this alone for now, but what was the point
// export const setHashCookie = () => {
//     let cookieName = 'createpointInitialUrlHash';
//     let cookieValue = window.location.hash;
//     if (cookieValue) {
//         document.cookie = cookieName + "=" + cookieValue + ";domain=qualcomm.com;path=/";
//     }
// };

export const getUrlParameter = (name, url) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        results = regex.exec(url);
    return results === null ? '' : results[1];
};

export const getUrlParams = () => {
    let params = {};
    window
        .location
        .search
        .replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
            params[key] = value;
        });

    return params;
};

// export const getSupportEmail = () => {
//     return process.env.REACT_APP_SUPPORT_EMAIL;
// };

// export const getLoginUrl = () => {
//     return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_LOGIN_ENDPOINT}`;
// };

// export const getLogoutUri = () => {
//     return `${process.env.REACT_APP_REDIRECT_LOGOUT_URI}`;
// };

// export const getTargetUrl = () => {
//     return `${process.env.REACT_APP_TARGET_RETURN_URL}`;
// };

// export const getRefreshTokenUrl = () => {
//     return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_TOKEN_REFRESH_ENDPOINT}`;
// };

// export const getTealiumUrl = () => {
//     return `${process.env.REACT_APP_TEALIUM_URL}`;
// };

// export const triggerTealiumTracking = (tealiumData) => {
//     trackEvent(tealiumData);
// };

// export const getAppNameForTealium = () => {
//     return `${process.env.REACT_APP_NAME_FOR_TEALIUM}`;
// };
// export const logout = (returnUrl) => {
//     let loggedInUserSessionId;
//     const token = getSessionToken();
//     if(token){
//         const currentUser = jwt.decode(token);
//         if (currentUser != null) {
//             loggedInUserSessionId = currentUser.sessionId;
//         }
//         //Removing the jwt token from local storage
//         localStorage.removeItem(`QID-${process.env.REACT_APP_ENV_SN}-JWT-TOKEN`);
//     }
//     window.location = `${getLogoutUri()}?code=${loggedInUserSessionId}&targetUrl=${returnUrl}`;


// };
// export const preferenceTealiumData = (data) => {
//     const tealiumData = {}
//     if (data) {
//         tealiumData['preferences_updated'] = data.type//"area of interest";
//         tealiumData['org_id'] = data.customerId;
//         tealiumData['user_name'] = data.qcGuid;
//     }
//     return tealiumData
// }
// export const getCompanyNameWithOrgId =(comapanyName,OrgId) =>{
//     return `${comapanyName} (${OrgId})`;
// };
