import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable"; 
import "regenerator-runtime/runtime";
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {ThemeProvider} from "@qc-modules/components"
import {defaultTheme} from "@qc-modules/styles"
import {OldBrowserModal} from "./components/common/OldBrowserModal";
import App from './App';

const rootElement = document.getElementById('root');

const ContentToRender = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        let show = false || !!document.documentMode;
        setShowModal(show);
    }, []);

    return (
        <div>
            {showModal && <OldBrowserModal showModal={showModal} setShowModal={setShowModal}/>}

            <React.StrictMode>
              <div id='modal'></div>
              <App />
            </React.StrictMode>
        </div>
    )
};

ReactDOM.render(
  <ContentToRender/>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
