import React, {useState} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import history from "../context/History";
import Routes from "./Routes";

const AppRouter = () => {
    // const [returnUrl] = useState(window.location.href);
    // const redirectToOauth = (returnUrl) => {
    //     window.location = targetUrl(returnUrl);
    // };
    // const targetUrl = (currentRoute) => {
    //     const url = `${getLoginUrl()}?state=${currentRoute}`;
    //     return (url);
    // };

    return (
        <Router history={history}>
            <Switch>
                <Routes/>
            </Switch>
        </Router>
    )
};

export default AppRouter;